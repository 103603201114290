import React from 'react'

import wpLogo from '../../images/tech-logos/wordpress.svg'
import reactLogo from '../../images/tech-logos/react.svg'
import gatsbyLogo from '../../images/tech-logos/gatsby.svg'
import twLogo from '../../images/tech-logos/tailwind.svg'
import adobeLogo from '../../images/tech-logos/adobe.svg'
import figLogo from '../../images/tech-logos/figma.svg'
import sassLogo from '../../images/tech-logos/sass.svg'

const logoSet = [
  {
    name: 'Adobe',
    alt: 'Adobe Creative Cloud (Photoshop, Illustrator, InDesign)',
    src: adobeLogo,
  },
  {
    name: 'Figma',
    alt: 'Figma Prototyping System',
    src: figLogo,
  },
  {
    name: 'React.js',
    alt: 'React.js',
    src: reactLogo,
  },
  {
    name: 'Gatsby',
    alt: 'Gatsby Static Site Generator',
    src: gatsbyLogo,
  },
  {
    name: 'Tailwind CSS',
    alt: 'Tailwind CSS Framework',
    src: twLogo,
  },
  {
    name: 'Sass',
    alt: 'Sass CSS Preprocessor',
    src: sassLogo,
  },
  {
    name: 'WordPress',
    alt: 'WordPress Content Management System',
    src: wpLogo,
  },
]

const TechLogo = ({ alt, src }) => {
  return (
    <div className="px-5 max-w-1/2 md:max-w-1/4 lg:w-unset my-3">
      <div className="object-contain object-center h-8">
        <img className="tech-logo h-full" alt={alt} title={alt} src={src} />
      </div>
    </div>
  )
}

const TechLogos = ({ children, ...rest }) => {
  const logoImages = logoSet.map(logo => (
    <TechLogo key={logo.name} alt={logo.alt} src={logo.src} />
  ))

  console.log(logoSet)

  return (
    <div
      className="tech-logos flex -mx-5 justify-center flex-wrap lg:flex-no-wrap -mb-3"
      {...rest}
    >
      {logoImages}
    </div>
  )
}

export default TechLogos
