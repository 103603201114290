import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { DefaultLayout } from '../components/Layouts'
import Meta from '../components/Meta'
import TechLogos from '../components/TechLogos'

const BackgroundSection = ({ className, children }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "work-banner.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 2600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`#040e18`}
        >
          {children}
        </BackgroundImage>
      )
    }}
  />
)

const IndexPage = () => (
  <DefaultLayout>
    <Meta title="Home" />
    <div className="max-w-4xl mx-auto">
      <div className="flex flex-wrap -mx-8 items-center">
        <div className="px-8 w-full lg:w-1/2 mb-8 lg:mb-0 text-4xl sm:text-5xl font-bold uppercase lg:text-right leading-tight page-title">
          Design.
          <br />
          Development.
          <br />
          Education.
        </div>
        <div className="px-8 w-full lg:w-auto flex-1">
          <span className="uppercase font-semibold">
            I don't play favorites.
          </span>{' '}
          I'm passionate about working with people who care about what they do,
          and that extends into all aspects of my professional life. Whether
          it's helping small businesses build an online presence, sharing my
          love of typography with students, or designing something beautiful, I
          am all about sparking creativity and spreading happiness.
        </div>
      </div>
    </div>
    <BackgroundSection className="full-width-container my-16 py-10 md:py-20 bg-primary">
      <div className="lg:max-w-4xl mx-5 lg:mx-auto bg-white p-6 md:p-10 relative z-10 shadow-lg rounded-lg text-center">
        <h3 className="text-2xl mb-4">
          <strong className="uppercase">I make things pretty</strong>
          <br />
          &hellip;but I also make things functional.
        </h3>
        <div className="row text-left">
          <div className="col md:w-1/2 mb-3 md:mb-0">
            I love to bring my sense of color, fonts, illustration, and design
            to a project. From clean and minimal to rich and detailed, it's my
            job to transform my clients' messages into something eye-catching.
          </div>
          <div className="col md:w-1/2">
            A design is no good if it doesn't work. Organization,
            well-structured code, and consistency are important to me, and I'm
            always learning the latest techniques to make projects more
            efficient and easy to use.
          </div>
        </div>
      </div>
    </BackgroundSection>
    <div className="container max-w-xl mx-auto text-center">
      <h3 className="text-2xl mb-4 uppercase font-bold">
        I can help you solve problems
      </h3>
      <p className="mb-8">
        I work as one half of{' '}
        <a href="https://www.northxsouth.co">North X South</a> and am the
        primary point of contact for graphic design and front-end web
        development.
      </p>
      <p className="text-center -my-2">
        <Link to="/about" className="btn btn-primary m-2 inline-block">
          Discover My Background
        </Link>
        <a
          href="https://www.northxsouth.co/projects"
          className="btn btn-primary m-2 inline-block"
        >
          View My Work
        </a>
      </p>
    </div>
    <div className="text-center mt-20">
      <h3 className="text-2xl mb-4 uppercase font-bold">I love technology</h3>
      <p className="mb-8">
        My skills are <Link to="/resume">pretty versatile</Link>, but these are
        a few of my favorite tools:
      </p>
      <TechLogos />
    </div>
  </DefaultLayout>
)

export default IndexPage
